@use 'variable' as *;
@use 'mixin';

$side-l: 25%;
$side-r: 75%;

.base-form {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 55em) {
        flex-direction: column;
    }

    .block-right {
        margin-left: $side-l;
        width: $side-r;

        @media (max-width: 55em) {
            margin-left: 0;
            width: 100%;
        }
    }

    .help,
    .help-text {
        font-size: 0.8em;
        margin-left: $side-l;
        width: $side-r;

        @media (max-width: 55em) {
            margin-left: 0;
            width: 100%;
        }
    }

    .text-form {
        width: 100%;
    }

    label {
        @include mixin.font-set(1.1em, $black, 400);

        padding-right: 1em;
        position: relative;
        width: $side-l;

        &::after {
            content: ':';
        }

        @media (max-width: 55em) {
            line-height: 1.5;
            width: 100%;
        }
    }

    abbr {
        @include mixin.font-set(1.1em, $error, bolder);

        position: absolute;
        transform: translate(0.4em, -0.1em);
    }

    input,
    textarea,
    select {
        @include mixin.font-set(1.1em, $black, 300);

        background-color: $white;
        border: 1px solid $black;
        border-radius: 0;
        box-shadow: 0;
        margin-bottom: 1em;
        padding: 0.4em 1em;
        width: $side-r;

        @media (max-width: 55em) {
            width: 100%;
        }
    }

    .errormessages {
        margin-left: $side-l;
        padding-left: 0;
        width: $side-r;

        @media (max-width: 55em) {
            margin-left: 0;
            width: 100%;
        }

        li {
            @include mixin.font-set(1.1em, $error, 900);

            list-style-image: none;
            padding: 0 0.45em;
        }
    }

    .booleanfield {
        align-items: baseline;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-left: $side-l;
        width: 100%;

        @media (max-width: 55em) {
            margin-left: 0;
        }

        label {
            width: auto;

            &::after {
                content: '';
            }
        }

        input {
            margin: 0 5px 0 1px;
            width: auto;
        }

        .errormessages {
            margin: 0;
            width: 100%;
        }
    }

    .checkbox {
        margin-bottom: 1em;
        width: 100%;

        > p {
            @include mixin.font-set(1.1em, $black, 400);

            float: left;
            line-height: 1;
            padding-right: 1em;
            padding-top: 0.23em;
            position: relative;
            width: $side-l;

            &::after {
                content: ':';
            }

            @media (max-width: 55em) {
                width: 100%;
            }
        }

        > label {
            float: right;
            line-height: 1.5;
            margin-bottom: 0;
            text-align: left;
            width: $side-r;

            &::after {
                content: '';
            }

            @media (max-width: 55em) {
                width: 100%;
            }

            input {
                margin: 0;
                padding: 0;
                width: auto;
            }
        }

        .text-danger {
            @include mixin.font-set(1.1em, $error, 900);

            background-color: transparent;
            float: right;
            line-height: 1.5;
            padding: 0 0.45em;
            width: $side-r;

            @media (max-width: 55em) {
                float: none;
            }
        }
    }

    ul[id*='id_'] {
        margin: 0 0 1em;
        padding: 0;
        width: $side-r;

        @media (max-width: 55em) {
            width: 100%;
        }

        li {
            list-style-image: none;
            list-style-type: none;
            margin-bottom: 0;

            input {
                margin: 0;
                padding: 0;
                width: auto;
            }

            label {
                @include mixin.font-set(1.1em, $black, 400);

                width: 100%;

                &::after {
                    content: '';
                }
            }
        }
    }

    input[type='file'] {
        border: 0;
    }

    input[type=file].drag-and-drop {
        background-color: $nav-line;
        background-image: url('../img/upload-file.svg');
        background-position: 88% center;
        background-repeat: no-repeat;
        border: 2px dashed #ccc;
        border-radius: 0.2em;
        margin-bottom: 1em;
        padding: 2em;
        width: 100%;
    }

    .upload-file-frame {
        width: $side-r;

        @media (max-width: 55em) {
            width: 100%;
        }

        .fail-upload {
            color: $error;
            text-decoration: line-through;

            img {
                margin-right: 0.4em;
                position: relative;
                top: 4px;
            }
        }
    }

    img {
        margin: 0 0 0.2em;
    }

    input[id*='captcha'] {
        margin-left: $side-l;

        @media (max-width: 55em) {
            margin-left: 0;
        }
    }

    input[name*='file'] {
        padding-left: 0;
    }

    .captcha {
        transform: translate(0, 1em);

        @media (max-width: 55em) {
            transform: translate(0, 0);
        }
    }

    w-auto {
        width: auto;
    }

    label + input[type=file].drag-and-drop {
        width: $side-r;

        @media (max-width: 55em) {
            width: 100%;
        }
    }
}
