@use 'variable' as *;
@use 'mixin';

.aldryn .aldryn-newsblog-pager ul.pager li {
    float: none;
    margin: 0;
}

.aldryn-newsblog-list {
    article {
        border-bottom: 0.1em solid $nav-line;
        padding-bottom: 1.5em;

        &:last-of-type {
            border: 0;
        }

        h2:not(:first-child) {
            margin-top: 0.5em;
        }
    }
}

.container.page-with-newsblog {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    aside {
        margin-left: 4vw;
        margin-right: 0;
        min-width: 18em;
        width: 18em;

        header {
            h1,
            h2,
            h3 {
                @include mixin.font-set(1.8em, $hover-color, 300);

                padding: 0;
            }
        }

        .newsblog {
            border: 0.2em solid $nav-line;
            padding: 0 2em 2em;
        }

        .aldryn-newsblog {
            border-bottom: 0.1em solid $nav-line;
            margin-bottom: 3em;
            padding-bottom: 0.45em;

            h2 {
                a {
                    color: $hover-color;
                    text-decoration: none;
                }
            }

            h2:not(:first-child) {
                margin-top: 0;
            }

            img {
                margin-top: 2em;
                max-width: 100%;
            }
        }
    }

    .alert-success {
        @include mixin.font-set(1.8em, $hover-color, 300);

        line-height: 1.33em;
        margin-bottom: 0.66em;
        padding-bottom: 1em;
    }
}

.is-homepage {
    .container.page-with-newsblog {
        justify-content: flex-end;

        aside {
            width: 25%;
        }
    }
}

.col-md-17 {
    width: 100%;
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    .container.page-with-newsblog {
        flex-direction: column;

        aside {
            margin-left: 0;
            width: 100%;
        }
    }

    .is-homepage {
        .container.page-with-newsblog {
            aside {
                width: 100%;
            }
        }
    }
}
