@use 'variable' as *;
@use 'mixin';

.chart-list {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4em;

    > div {
        align-items: stretch;
        background-color: $hover-color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 2.9em;
        padding: 3em;
        width: 31%;

        @media (max-width: 980px) {
            width: 46%;
        }

        @media (max-width: 767px) {
            margin: 0 auto 2.5em;
            width: 100%;
        }

        @media (max-width: 400px) {
            width: 95%;
        }
    }

    img {
        display: block;
        margin: 0 auto 2em;
        max-height: 10em;
    }

    h2 {
        @include mixin.font-set(1.6em, $white, 900);

        span {
            display: block;
            font-size: 0.66em;
        }
    }

    p {
        color: $white;
    }

    a {
        @include mixin.font-set(1em, $hover-color, 900);

        background-color: $nav-line;
        border: 1px solid $nav-line;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        display: block;
        padding: 0.5em;
        text-decoration: none;
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .btn-link {
        background: $bg1;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        display: inline-block;
        position: relative;
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.15s;
        transition-property: color;
        vertical-align: middle;;
    }

    .btn-link::before {
        background: $white;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: scaleY(0);
        transform-origin: 50%;
        transition-duration: 0.15s;
        transition-property: transform;
        transition-timing-function: ease-out;
        z-index: -1;
    }

    .btn-link:hover::before,
    .btn-link:focus::before,
    .btn-link:active::before {
        transform: scaleY(1);
    }

    .width-auto {
        display: inline-block;
        min-width: 50%;
        width: auto;
    }
}
