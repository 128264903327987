@use 'variable' as *;
@use 'mixin';

.nav-social {
    float: right;
    margin-top: 7px;

    li {
        display: inline-block;
        padding-left: 1em;

        a {
            color: $input-text;
            text-decoration: none;

            &:hover {
                color: $title;
            }

            img,
            svg {
                height: 2em;
                opacity: 0.4;
                transition: 0.1s;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

nav {
    border-bottom: 0.2em solid $nav-line;
    border-top: 0.2em solid $nav-line;
    margin-bottom: 3em;
    padding: 0 $width-large;

    #page-navigation {
        display: inline-block;

        .active {
            box-shadow: 0 0.2em 0 0 $hover-color;
        }

        li {
            display: inline-block;
            line-height: 3;
            margin-right: 1em;

            a {
                @include mixin.font-set(1em, $title, bold);

                &:hover {
                    color: $hover-color;
                }
            }
        }
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    nav {
        padding: 0 $width-middle;
    }
}

/*
*
* media-max: 55em
*
*/
@media (max-width: 55em) {
    nav {
        border: 0;
        margin: 1em;
        padding: 0;

        .nav-social {
            display: none;
            float: none;

            li {
                display: inline-block;
                margin: 2em 1em 1em;
                padding: 0;
            }
        }

        #page-navigation {
            display: none;

            .active {
                box-shadow: -0.2em 0 0 0 $hover-color;
            }

            li {
                display: block;
                line-height: 3;
                margin-right: 1em;
                padding: 0 0 0 1em;

                a {
                    @include mixin.font-set(1em, $title, bold);
                }
            }
        }
    }
}
